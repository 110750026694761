import { createRouter, createWebHistory } from 'vue-router';
const routes = [
    {
        path: '/admin/load-po',
        component: () => import('@/layout/BillingFormLayout.vue'),
        redirect: '/admin/load-po/menunggu-load-po/produk-existing-aktif',
        children: [
            {
                path: 'menunggu-load-po/produk-existing-aktif',
                name: 'WaitingBillingFormView',
                component: () => import('@/views/WaitingLoadPOView.vue'),
            },
            {
                path: 'selesai',
                name: 'CompletedLoadPOView',
                component: () => import('@/views/CompletedLoadPOView.vue'),
            },
        ],
    },
    {
        path: '/admin/load-po/detail',
        name: 'DetailBillingFormView',
        component: () => import('@/views/DetailLoadPO.vue'),
    },
];
const router = createRouter({
    history: createWebHistory((import.meta.env || process.env).BASE_URL),
    routes,
});
export default router;
